export default {
  mobile: "767",
  sm: "768",
  md: "992",
  lg: "1300",
  compassWidth: "56",
  ringWidth: "10",
  spacing: "5",
  frontComponentZIndex: "99",
  radius40Button: "20",
  radiusLarge: "4px",
  radiusSmall: "2px",
  overlay: "rgba(255, 255, 255, 0.15)",
  overlayInvert: "rgba(255, 255, 255, 0.85)",
  colorPrimary: "#519ac2",
  colorSplitter: "#ff6f00",
  textLight: "#ffffff",
  textLightDimmed: "#bbbbbb",
  textDark: "#595b60",
  textDarker: "#4d5766",
  textBlack: "#000",
  dark: "#3f4854",
  darkWithOverlay: "#667080",
  grey: "#888888",
  greyLighter: "#ddd",
  greyLighter2: "#e6e6e6",
  greyLightest: "#f2f2f2",
  charcoalGrey: "#3f4854",
  modalBg: "#fff",
  modalText: "#000",
  modalHighlight: "#519ac2",
  mapButtonColor: "#9ca1aa",
  mapButtonTop: "18",
  mapNavigationTop: "80",
  trainerHeight: "64",
  workbenchWidth: "350"
};
